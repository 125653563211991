import { ContentModel } from "./content";

export const HeroContent: ContentModel = {
  header: "Experience your new home for",
  subtitle: "all things HR",
  body: "Simplify workflows, boost productivity, and unleash the full potential of your workforce, with our cutting-edge HR cloud solution",
};

export const ClientsTickerContent: ContentModel = {
  header:
    "We have been helping agile organizations manage their single greatest asset,",
  body: "HRFlex™ is a comprehensive platform designed to revolutionize the way organizations manage their human resources; it empowers HR professionals to streamline their processes, enhance efficiency, and unlock the true potential of their workforce.",
};

export const clients: { icon: string; alt: string }[] = [
  { icon: "/alphamorgan.svg", alt: "alpha morgan" },
  { icon: "/cdl.svg", alt: "Credit Direct Limited" },
  { icon: "/hbc.svg", alt: "Heritage Bank" },
  { icon: "/hiosl.svg", alt: "HIOSL" },
  { icon: "/egbin.svg", alt: "Egbin Power" },
  { icon: "/ril.svg", alt: "Resource Intermediaries Limited" },
  { icon: "/tsl.png", alt: "TSL" },
  { icon: "/gol.svg", alt: "Grand Oal Limited" },
];

export const features: {
  header: string;
  title: string;
  copy: string;
  image: string;
  href: string;
}[] = [
  {
    header: "01",
    title: "Core HR",
    copy: "Efficiently manage the candidate selection process by tracking applications, reviewing resumes, and collaborating with hiring teams.",
    image: "/recruitment.png",
    href: "",
  },
  {
    header: "02",
    title: "Talent Acquisition & Management",
    copy: "Simplify and automate your travel and expense management workflow and empower your organization to efficiently manage travel requests, track expenses, and streamline reimbursements.",
    image: "/travel-expense.png",
    href: "",
  },

  {
    header: "03",
    title: "Performance",
    copy: "HRFlex™’s Performance Management module streamlines evaluations, goal setting, and development planning.",
    image: "/performance.png",
    href: "",
  },
  {
    header: "04",
    title: "Compensation & Benefits",
    copy: "Scalable for all kinds of payroll and payroll inputs. Save time and improve efficiency with HRFlex™ Payroll Management",
    image: "/payroll.png",
    href: "",
  },
  {
    header: "05",
    title: "Time Off and Absence Management",
    copy: "Managing employee exits and off-boarding processes is crucial for maintaining organizational integrity and transitioning employees",
    image: "/time-off.png",
    href: "",
  },
];

export const testimonials = [
  {
    body: "As a rapidly growing company, managing our HR processes was becoming a significant challenge until we implemented HRFlex™. The software’s robustness and flexibility across all modules is really impressive. We can easily accommodate our evolving policies and ensure compliance with ease. From Recruitment, loans, payroll, confirmation, performance mgt, etc; the automated processes have eliminated bottlenecks and manual errors!",
    author: {
      name: "Angela Bazuaye",
      handle: "Ag. Head, HR",
      imageUrl: "/angela.jpeg",
    },
  },
  {
    body: "Since implementing the Performance Management Module in our organization, we have experienced a transformative shift in our employee appraisal process which has fostered a culture of continuous improvement. The intuitive and automated workflows have significantly streamlined our HR processes, saving us valuable time and resources.",
    author: {
      name: "Izuchukwu Okafor",
      handle: "Head, Performance Management",
      imageUrl: "/izu.jpeg",
    },
  },
  {
    body: "HRFlex™ HRIS presents a robust and versatile solution that has aligned seamlessly with our HR outsourcing objectives over the past decade. By leveraging its comprehensive features, customization options, data security measures, communication tools, analytics and user-friendly interfaces, we have confidently optimized our HR processes and procedures.",
    author: {
      name: "Joseph Adagbogun (FCHIHRSM)",
      handle: "Group Head, Talent & Culture Advisory",
      imageUrl: "/ja.jpeg",
    },
  },
];
