import { createBrowserRouter } from "react-router-dom";
import Home from "../app/page";
import Packages from "../app/packages";
import Page from "../app/performance";
import ExpenseHome from "../app/expense";
import PerformanceHome from "../app/performance";
import CompensationHome from "../app/compensation";
import AbsenceHome from "../app/absence";
import HRFlexCore from "../app/core";
import RecruitmentHome from "../app/recruitment";
import { ErrorPage } from "../app/404";

export enum ROUTES {
  root = "/",
  packages = "/packages",
  performance = "/performance",
  expense = "/expense",
  compensation = "/compensation",
  core = "/core",
  recruitment = "/recruitment",
}

export const router = createBrowserRouter([
  {
    path: ROUTES.root,
    element: <Home />,
  },
  {
    path: ROUTES.packages,
    element: <Packages />,
  },
  {
    path: ROUTES.compensation,
    element: <CompensationHome />,
  },
  {
    path: ROUTES.expense,
    element: <ExpenseHome />,
  },
  {
    path: ROUTES.performance,
    element: <PerformanceHome />,
  },
  {
    path: ROUTES.core,
    element: <HRFlexCore />,
  },
  {
    path: ROUTES.recruitment,
    element: <RecruitmentHome />,
  },
  { path: "*", element: <ErrorPage />, errorElement: <ErrorPage /> },
]);
