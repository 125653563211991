import {
  LeftRightContentImageCard,
  ModuleHero,
  Button,
  SectionWithGrid,
} from "../components";
import { BasePageLayout } from "../components/BasePageLayout";
import {
  heroContent,
  featureToast,
  worryFree,
  taxManagement,
  features,
} from "../content/Compensation";

export default function CompensationHome() {
  return (
    <BasePageLayout title="Compensation">
      <div className="mt-16">
        <ModuleHero content={heroContent} />
        <LeftRightContentImageCard
          content={featureToast}
          skin={"dark"}
          contentDirection="left"
          contentButton={
            <Button
              componentType="link"
              label="Contact Sales"
              fillWidth={true}
              link="mailto:info@hr-flex.com?subject=Contact Sales"
            />
          }
        />
        <LeftRightContentImageCard
          content={worryFree}
          skin={"default"}
          contentDirection="right"
        />
        <LeftRightContentImageCard
          content={taxManagement}
          skin={"default"}
          contentDirection="left"
        />
        <SectionWithGrid grid="2" gridContent={features} />
      </div>
    </BasePageLayout>
  );
}
