import {
  ModuleHero,
  LeftRightContentImageCard,
  Button,
  SectionWithGrid,
} from "../components";
import { BasePageLayout } from "../components/BasePageLayout";
import {
  heroContent,
  featureToast,
  cardOne,
  cardTwo,
  cardThree,
  cardFour,
  features,
} from "../content/Expense";

export default function ExpenseHome() {
  return (
    <BasePageLayout title="Expense Management">
      <div className="mt-16">
        <ModuleHero content={heroContent} />
        <LeftRightContentImageCard
          content={featureToast}
          skin={"dark"}
          contentDirection="left"
          // contentButton={
          //   <Button
          //     componentType="link"
          //     label="Contact Sales"
          //     fillWidth={true}
          //     link="mailto:info@hr-flex.com?subject=Contact Sales"
          //   />
          // }
        />
        <LeftRightContentImageCard
          content={cardOne}
          skin={"light"}
          contentDirection="right"
        />
        <LeftRightContentImageCard
          content={cardTwo}
          skin={"default"}
          contentDirection="left"
        />

        <SectionWithGrid grid="2" gridContent={features} />
      </div>
    </BasePageLayout>
  );
}
