export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

/** This function simulates a loading animation. The duration(in seconds) determines how long the animation stays */
export const simulateLoader = (setIsLoading: Function, duration: number) => {
  let timer = setTimeout(function () {
    setIsLoading(false);
  }, duration);

  return () => clearTimeout(timer);
};
