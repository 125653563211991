import { classNames } from "../lib/helperFunctions";
import { ContentModel } from "../content/content";
import { FC, ReactNode } from "react";

export interface Feature {
  name: string;
  description?: string;
  href?: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
}
export const SectionWithGrid: FC<{
  content?: ContentModel;
  gridContent: Feature[];
  grid: "2" | "3";
}> = ({ content, gridContent, grid }) => {
  return (
    <div className="px-6 py-12 bg-white lg:px-8 ">
      <svg
        className="absolute transform right-full translate-x-1/4 translate-y-1/4 lg:translate-x-1/4"
        width={404}
        height={784}
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={2}
              height={2}
              className="text-primary-300"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={784}
          fill="url(#4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa)"
        />
      </svg>
      {/* <svg
        className="absolute transform left-full -translate-x-1/4 -translate-y-3/4 md:-translate-y-1/3 lg:-translate-x-1/4"
        width={404}
        height={784}
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={2}
              height={2}
              className="text-primary-300"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={784}
          fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
        />
      </svg> */}
      <div className="mx-auto max-w-7xl">
        {content && (
          <div className="max-w-2xl mx-auto mb-16 lg:text-center lg:mb-24 sm:mt-20">
            {content.header && (
              <h2 className="text-base font-semibold leading-7 text-success-600">
                {content.header}
              </h2>
            )}

            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {content.subtitle}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {content.body}
            </p>
          </div>
        )}
        <div className="max-w-2xl pb-20 mx-auto lg:max-w-none">
          <dl
            className={classNames(
              grid === "2" ? "lg:grid-cols-2" : "lg:grid-cols-3",
              "grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none "
            )}
          >
            {gridContent.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center text-base font-semibold leading-7 text-gray-900 gap-x-3">
                  <feature.icon
                    className="flex-none w-5 h-5 text-primary-600"
                    aria-hidden="true"
                  />
                  {/* {feature.icon} */}
                  {feature.name}
                </dt>
                <dd className="flex flex-col flex-auto mt-4 text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  {/* <p className="mt-6">
                <a
                  href={feature.href}
                  className="text-sm font-semibold leading-6 text-primary-600"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
