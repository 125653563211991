import { FC } from "react";
import { classNames } from "../lib/helperFunctions";
import { Disclosure, Transition } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

export const AccordionHolder: FC<{
  dark: boolean;
  header: string;
  title: string;
  defaultOpen: boolean;
  copy: string;
  link: string;
}> = ({ dark, header, title, defaultOpen, copy, link }) => {
  return (
    <>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <div className="my-6 md:my-10">
            <div className="flex justify-between gap-6">
              <div>
                <div>
                  <h2 className="text-base font-semibold tracking-tight text-left text-gray-400 uppercase">
                    {header}
                  </h2>
                  <div
                    className={classNames(
                      dark ? "bg-warning-500" : "bg-warning-500",
                      "w-12 h-0.5 mt-2 "
                    )}
                  ></div>
                </div>

                <p
                  className={classNames(
                    dark ? "text-gray-100" : "text-gray-900",
                    "relative mt-6 text-lg font-semibold leading-8 tracking-tight  sm:text-3xl"
                  )}
                >
                  {title}
                </p>
              </div>
              <Disclosure.Button>
                <div
                  className={classNames(
                    open ? "bg-gray-700" : "bg-gray-600",
                    "p-3  rounded-full hover:bg-gray-900 "
                  )}
                >
                  {open ? (
                    <MinusIcon className="w-6 h-6 text-gray-100" />
                  ) : (
                    <PlusIcon className="w-6 h-6 text-gray-100" />
                  )}
                </div>
              </Disclosure.Button>
            </div>

            <Transition
              show={open}
              enter="transition duration-600 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel
                static
                className="grid py-8 leading-8 text-gray-200 text-md"
              >
                <div className="gap-8 align-top sm:grid sm:grid-cols-2 ">
                  <div className="space-y-6">
                    <div>{copy}</div>
                    <a
                      href={link}
                      className="flex items-center gap-2 font-medium hover:text-teal-600 text-success-600"
                    >
                      Learn More{" "}
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  {/* <div className="border border-gray-800 rounded-2xl bg-opacity-10 backdrop-blur backdrop-filter bg-primary-900">
                    <img src={image} className="mx-auto object-fit sm:h-80" />
                  </div> */}
                </div>
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </>
  );
};
