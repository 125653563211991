export type Tiers = {
  name: string;
  id: string;
  href: string;
  priceMonthly?: string;
  description: string;
  mostPopular: boolean;
  features: string[];
  featured: boolean;
  mainCard: boolean;
};

export type Tier = {
  Basic: boolean | string;
  Professional: boolean | string;
  Enterprise: boolean | string;
};
export interface Feature {
  name: string;
  tiers: Tier;
}
export interface Section {
  name: string;
  features: Feature[];
  isAddOn?: boolean;
}
export const tiers: Tiers[] = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "mailto:info@hr-flex.com?subject=Contact Sales - Basic Package",
    // priceMonthly: "$9",
    description: "Only the essentials. Ideal for Small Businesses",
    mostPopular: false,
    features: [
      "Employee Self Services",
      "Employee Records Management",
      "Celebrations",
      "Time Off & Leave Management",
      "Report & Analytics",
      "Email Support",
    ],
    featured: false,
    mainCard: true,
  },
  {
    name: "Professional",
    id: "tier-professional",
    href: "mailto:info@hr-flex.com?subject=Contact Sales - Professional Package",
    // priceMonthly: "$29",
    description:
      "For scaling businesses, and large enterprises. Includes everything in Basic",
    mostPopular: true,
    features: [
      "Onboarding",
      "Exit Management",
      "Awards & Recognitions",
      "Training & Development",
      "Custom Reports",
      "Phone & Chat Support",
    ],
    featured: true,
    mainCard: true,
  },
  {
    name: "Payroll Management",
    id: "tier-comp",
    href: "mailto:info@hr-flex.com?subject=Contact Sales - Basic Package",
    // priceMonthly: "$9",
    description: "Only the essentials. Ideal for Small Businesses",
    mostPopular: false,
    features: [
      "Benefits Management",
      "Remittances",
      "Pay Scheduling",
      "Direct Deposits",
      "Payroll Report & Analytics",
      "Loan Management",
      "Integration Hub",
    ],
    featured: false,
    mainCard: false,
  },
  {
    name: "Performance Management",
    id: "tier-performance",
    href: "mailto:info@hr-flex.com?subject=Contact Sales - Professional Package",
    // priceMonthly: "$29",
    description: "For scaling businesses. Includes everything in basic",
    mostPopular: true,
    features: [
      "KPIs & Scorecards",
      "360 Feedback & Reviews",
      "Conduct Management",
      "Surveys",
      "Goals",
      "Performance Reports",
      "Integration Hub",
    ],
    featured: false,
    mainCard: false,
  },
  {
    name: "Recruitment",
    id: "tier-talent",
    href: "mailto:info@hr-flex.com?subject=Contact Sales - Professional Package",
    // priceMonthly: "$29",
    description: "For scaling businesses. Includes everything in basic",
    mostPopular: true,
    features: [
      "Online Candidate Testing",
      "Interview Management",
      "Outsourced Staff Management",
      "Integration Hub",
    ],
    featured: false,
    mainCard: false,
  },
  {
    name: "Expense Management",
    id: "tier-expense",
    href: "mailto:info@hr-flex.com?subject=Contact Sales - Professional Package",
    // priceMonthly: "$29",
    description: "For scaling businesses. Includes everything in basic",
    mostPopular: true,
    features: [
      "Cash Advance & Retirement",
      "Travel Management",
      "Invoice Management",
      "Integration Hub",
    ],
    featured: false,
    mainCard: false,
  },
  // {
  //   name: "Enterprise",
  //   id: "tier-enterprise",
  //   href: "mailto:info@hr-flex.com?subject=Contact Sales - Enterprise Package",
  //   priceMonthly: "$59",
  //   description: "Includes everything in basic and professional",
  //   mostPopular: false,
  //   features: [
  //     "25 products",
  //     "Up to 10,000 subscribers",
  //     "Advanced analytics",
  //     "24-hour support response time",
  //   ],
  //   featured: false,
  // },
];
export const _sections: Section[] = [
  {
    name: "Modules",
    features: [
      {
        name: "Employee Self Services",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Employee Records Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Time Off & Leave Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Celebrations",
        tiers: {
          Basic: "Birthdays, Anniversaries",
          Professional: "Awards & Recognitions",
          Enterprise: true,
        },
      },
      {
        name: "Payroll Management",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: "Disbursement",
        },
      },
      {
        name: "Performance Management",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: "360 Feedback & Appraisals",
        },
      },
      {
        name: "Surveys",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Benefits Administration",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: "Advanced Benefits",
        },
      },
      {
        name: "Time & Attendance",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Conduct Management",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Single Sign On (SSO)",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Exit Management",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Talent Acquisition & Recruitment",
        tiers: {
          Basic: false,
          Professional: false,
          Enterprise: true,
        },
      },
      {
        name: "Outsourced Staff Management",
        tiers: {
          Basic: false,
          Professional: false,
          Enterprise: true,
        },
      },
      {
        name: "Training & Development",
        tiers: {
          Basic: false,
          Professional: false,
          Enterprise: true,
        },
      },
      {
        name: "Online Candidate Testing",
        tiers: {
          Basic: false,
          Professional: false,
          Enterprise: true,
        },
      },
    ],
  },

  {
    name: "Add Ons",
    features: [
      {
        name: "Cash Advance & Expense Retirement",
        tiers: {
          Basic: false,
          Professional: "Contact Sales",
          Enterprise: "Contact Sales",
        },
      },
      {
        name: "HRFlex™ Integration Hub",
        tiers: {
          Basic: false,
          Professional: "Contact Sales",
          Enterprise: "Contact Sales",
        },
      },
    ],
  },
  //   {
  //     name: "Reporting",
  //     features: [
  //       {
  //         name: "Advanced analytics",
  //         tiers: { basic: true, professional: true, enterprise: true },
  //       },
  //       {
  //         name: "basic reports",
  //         tiers: { professional: true, enterprise: true },
  //       },
  //       { name: "professional reports", tiers: { enterprise: true } },
  //       { name: "Custom report builder", tiers: { enterprise: true } },
  //     ],
  //   },
];

export const sections: Section[] = [
  {
    name: "Core HRFlex™",
    features: [
      {
        name: "Employee Self Services",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Employee Records Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Celebrations",
        tiers: {
          Basic: "Birthdays, Anniversaries",
          Professional: "Awards & Recognitions",
          Enterprise: true,
        },
      },
      {
        name: "Time Off & Leave Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Onboarding",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Exit Management",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Training & Development",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Reports & Analytics",
        tiers: {
          Basic: "Standard",
          Professional: "Advanced & Custom",
          Enterprise: true,
        },
      },
    ],
  },
  {
    name: "Compensation & Benefits",
    isAddOn: true,
    features: [
      {
        name: "Benefits Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Remittances",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: "Contact Sales",
        },
      },
      {
        name: "Pay Scheduling",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Direct Deposit",
        tiers: {
          Basic: false,
          Professional: "Contact Sales",
          Enterprise: true,
        },
      },
      {
        name: "Payroll Reports & Analytics",
        tiers: {
          Basic: "Standard",
          Professional: "Advanced & Custom",
          Enterprise: true,
        },
      },
      {
        name: "Loan Management",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Integration Hub",
        tiers: {
          Basic: "Contact Sales",
          Professional: "Contact Sales",
          Enterprise: true,
        },
      },
    ],
  },
  {
    name: "Performance Management",
    isAddOn: true,
    features: [
      {
        name: "KPIs, Scorecards & Appraisals ",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "360 Feedback & Reviews",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Conduct Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Surveys",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Goals",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Reports & Analytics",
        tiers: {
          Basic: "Standard",
          Professional: "Advanced & Custom",
          Enterprise: true,
        },
      },
      {
        name: "Time & Attendance",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Integration Hub",
        tiers: {
          Basic: "Contact Sales",
          Professional: "Contact Sales",
          Enterprise: true,
        },
      },
    ],
  },
  {
    name: "Recruitment",
    isAddOn: true,
    features: [
      {
        name: "Online Candidate Testing ",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Interview Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Outsourced Staff Management",
        tiers: {
          Basic: false,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Integration Hub",
        tiers: {
          Basic: "Contact Sales",
          Professional: "Contact Sales",
          Enterprise: true,
        },
      },
    ],
  },
  {
    name: "Expense Management",
    isAddOn: true,
    features: [
      {
        name: "Cash Advance & Expense Retirement",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Travel Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },

      {
        name: "Invoice Management",
        tiers: {
          Basic: true,
          Professional: true,
          Enterprise: true,
        },
      },
      {
        name: "Integration Hub",
        tiers: {
          Basic: "Contact Sales",
          Professional: "Contact Sales",
          Enterprise: true,
        },
      },
    ],
  },
];
