import { ComparePlans, PackageCard } from "../components";
import { BasePageLayout } from "../components/BasePageLayout";
function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}
export default function Packages() {
  return (
    <BasePageLayout title="Packages">
      <div className="pt-24 bg-white sm:pt-32">
        <PackageCard />
        <ComparePlans />
      </div>
    </BasePageLayout>
  );
}
