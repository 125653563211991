import { ContentModel } from "../content/content";
import { Button } from ".";
import { FC, ReactNode } from "react";
import { classNames } from "../lib/helperFunctions";
export const LeftRightContentImageCard: FC<{
  content: ContentModel;
  contentButton?: ReactNode;
  contentDirection?: "left" | "right";
  skin?: "default" | "light" | "dark";
}> = ({ content, contentButton, contentDirection, skin }) => {
  return (
    <div
      className={classNames(
        skin === "dark"
          ? "bg-gray-900 text-gray-100"
          : skin === "light"
          ? "bg-primary-light text-slate-800"
          : "text-slate-800 transparent",
        "relative isolate overflow-hidden "
      )}
    >
      <div className="px-6 py-12 sm:px-6 sm:py-16 lg:px-8">
        <div className="items-center grid-cols-2 mx-auto space-y-4 max-w-7xl sm:gap-4 sm:grid">
          <div
            className={classNames(
              "text-left",
              contentDirection === "right" ? "order-last" : ""
            )}
          >
            <h2 className="text-3xl font-bold tracking-tight sm:text-3xl">
              {content.header}
            </h2>
            <p className="max-w-xl mt-6 leading-8 ">{content.body}</p>
            {contentButton && (
              <div className="flex items-center justify-center max-w-xs mt-10 gap-x-6">
                {contentButton}
              </div>
            )}
          </div>
          <div className="items-center mx-auto">
            <img
              className="w-full sm:w-4/5 sm:max-h-96"
              src={content.imageUrl}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
