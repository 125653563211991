import {
  ModuleHero,
  LeftRightContentImageCard,
  Button,
  SectionWithGrid,
  CenterImageContentCard,
} from "../components";
import { BasePageLayout } from "../components/BasePageLayout";
import {
  heroContent,
  featureToast,
  attractTopTalent,
  seamlessOnboarding,
  features,
} from "../content/Recruitment";

export default function RecruitmentHome() {
  return (
    <BasePageLayout title="Recruitment">
      <div className="mt-16">
        <ModuleHero content={heroContent} />
        <LeftRightContentImageCard
          content={featureToast}
          skin={"dark"}
          contentDirection="left"
          // contentButton={
          //   <Button
          //     componentType="link"
          //     label="Contact Sales"
          //     fillWidth={true}
          //     link="mailto:info@hr-flex.com?subject=Contact Sales"
          //   />
          // }
        />
        <LeftRightContentImageCard
          content={attractTopTalent}
          skin={"light"}
          contentDirection="right"
        />
        <CenterImageContentCard
          content={seamlessOnboarding}
          skin={"default"}
          // contentButton={
          //   <Button
          //     componentType="link"
          //     label="Contact Sales"
          //     fillWidth={true}
          //     link="mailto:info@hr-flex.com?subject=Contact Sales"
          //   />
          // }
        />
        <SectionWithGrid grid="2" gridContent={features} />
      </div>
    </BasePageLayout>
  );
}
