import {
  ModuleHero,
  LeftRightContentImageCard,
  SectionWithGrid,
  Button,
} from "../components";
import { BasePageLayout } from "../components/BasePageLayout";
import {
  featureToast,
  heroContent,
  cardFour,
  cardOne,
  cardThree,
  cardTwo,
  features,
} from "../content/Core";

export default function HRFlexCore() {
  return (
    <BasePageLayout title="Core">
      <div className="mt-16">
        <ModuleHero content={heroContent} />
        <LeftRightContentImageCard
          content={cardOne}
          skin={"dark"}
          contentDirection="left"
        />
        <LeftRightContentImageCard
          content={cardTwo}
          skin={"default"}
          contentDirection="right"
        />

        <SectionWithGrid grid="2" gridContent={features} />
      </div>
    </BasePageLayout>
  );
}
