import { ContentModel } from "../content/content";
import { FC } from "react";
export const ModuleHero: FC<{ content: ContentModel; dual?: boolean }> = ({
  content,
  dual,
}) => {
  return (
    <div className="relative isolate">
      <div
        className="absolute inset-x-0 overflow-hidden -top-40 -z-10 transform-gpu blur-3xl sm:top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#fffaeb] to-[#12B76A] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="max-w-4xl px-3 py-12 mx-auto text-center sm:py-24">
        <h1 className="inline-flex items-center px-2 py-1 text-sm font-bold text-green-700 rounded-md bg-green-50 ring-1 ring-inset ring-green-600/20">
          {content.header}
        </h1>
        {/* <h1 className="inline-flex items-center text-base font-medium text-green-700 ">
          {content.header}
        </h1> */}

        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {content.subtitle}
        </h2>
        <p className="mt-8 leading-8 text-slate-900">{content.body}</p>
        <div className="p-4 mt-10 bg-gray-100 rounded-3xl bg-opacity-20 backdrop-blur backdrop-filter ring-2 ring-gray-25">
          <img
            className="w-full shadow-sm rounded-3xl"
            alt=""
            src={content.imageUrl}
            //   width={600}
            //   height={600}
          />
        </div>
      </div>
    </div>
  );
};
