import { ContentModel } from "../content/content";
import { Button } from ".";
import { FC, ReactNode } from "react";
import { classNames } from "../lib/helperFunctions";
export const CenterImageContentCard: FC<{
  content: ContentModel;
  contentButton?: ReactNode;
  skin?: "default" | "light" | "dark";
}> = ({ content, contentButton, skin }) => {
  return (
    <div
      className={classNames(
        skin === "dark"
          ? "bg-gray-900 text-gray-100"
          : skin === "light"
          ? "bg-primary-light text-gray-700"
          : "text-gray-700 transparent",
        "relative isolate overflow-hidden "
      )}
    >
      <div className="px-6 py-12 sm:px-6 sm:py-24 lg:px-8">
        <div className="items-center mx-auto max-w-7xl sm:gap-4">
          <div className={classNames("text-center mb-4")}>
            <h2 className="text-3xl font-semibold tracking-tight sm:text-4xl">
              {content.header}
            </h2>
            <p className="max-w-6xl mx-auto mt-6 leading-8">{content.body}</p>
            {contentButton && (
              <div className="flex items-center justify-center max-w-xs mx-auto mt-6 gap-x-6">
                {contentButton}
              </div>
            )}
          </div>
          <div className="items-center mx-auto mt-12">
            <img
              className="w-full mx-auto sm:w-4/5 sm:max-h-96"
              src={content.imageUrl}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
