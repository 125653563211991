import { Feature } from "../components";
import { ContentModel } from "./content";
import {
  PresentationChartLineIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
export const heroContent: ContentModel = {
  header: "Performance Management",
  subtitle: "Empowering Organizations to streamline Performance Evaluations",
  body: "We understand the value of cultivating a high-performing workforce, and our Performance Management Module is designed to help you do just that. HRFlex™ empowers organizations to streamline performance evaluations, set clear goals, provide continuous feedback, and foster a culture of excellence.",
  imageUrl: "/performance-main.svg",
};

export const featureToast: ContentModel = {
  header: "Streamlined Performance Reviews",
  body: "Say goodbye to cumbersome paper-based reviews and inefficient processes. HRFlex™’s Performance Management Module offers a streamlined approach to performance evaluations. With customizable review templates, automatic reminders, and flexible scheduling, you can conduct performance reviews with ease and efficiency. Employees and managers can collaborate, set objectives, and track progress throughout the review period.",
  imageUrl: "/performance-reviews.svg",
};

export const goalSetting: ContentModel = {
  header: "Goal Setting and Tracking",
  body: "Align your employees’ goals with your organization’s strategic objectives using HRFlex™’s Goal Setting and Tracking features. Managers can set SMART (Specific, Measurable, Achievable, Relevant, Time-bound) goals for their team members, ensuring clarity and alignment. HRFlex™’s Balanced Scorecard feature offers a holistic view of organizational performance, incorporating a wide range of key performance indicators (KPIs) that ensure that performance evaluation considers various aspects and perspectives of the organization’s success.",
  imageUrl: "/goal-setting.svg",
};

export const features: Feature[] = [
  {
    name: "360-degree Feedback",
    description:
      "Gain a comprehensive understanding of employee performance with 360-degree feedback. HRFlex™ enables multi-rater assessments, allowing employees to receive feedback from peers, subordinates, and managers. This provides a fair assessment process across board and supports well-rounded development.",
    href: "#",
    icon: UserGroupIcon,
  },
  {
    name: "Performance Improvement Plans",
    description:
      "In cases where performance needs improvement, HRFlex™ supports Performance Improvement Plans (PIPs). Managers can collaboratively work with employees to create personalized improvement plans, setting clear expectations and providing necessary support to help employees succeed.",
    href: "#",
    icon: PresentationChartLineIcon,
  },
];
