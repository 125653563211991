import { CheckIcon } from "@heroicons/react/20/solid";
import { tiers } from "../../content/Packages";
import { classNames } from "../../lib/helperFunctions";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";
// const tiers = [
//   {
//     name: "Basic",
//     id: "tier-basic",
//     href: "#",
//     priceMonthly: "$19",
//     description:
//       "The perfect plan if you're just getting started with our product.",
//     features: [
//       "25 products",
//       "Up to 10,000 subscribers",
//       "Advanced analytics",
//       "24-hour support response time",
//     ],
//     featured: false,
//   },
//   {
//     name: "Professional",
//     id: "tier-professional",
//     href: "#",
//     priceMonthly: "$49",
//     description: "Dedicated support and infrastructure for your company.",
//     features: [
//       "Unlimited products",
//       "Unlimited subscribers",
//       "Advanced analytics",
//       "Dedicated support representative",
//       "Marketing automation",
//       "Custom integrations",
//     ],
//     featured: true,
//   },
// ];

export const PackageCard = () => {
  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="relative py-12 mx-4 bg-white isolate">
        <div
          className="absolute inset-x-0 overflow-hidden -top-3 -z-10 transform-gpu px-36 blur-3xl"
          aria-hidden="true"
        >
          <div
            className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#3F52E3] to-[#039855] opacity-30"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <Transition.Child
          enter="transition-opacity duration-700 ease-linear"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          //   leave="transition ease-in-out duration-700 transform"
          //   leaveFrom="translate-x-0"
          //   leaveTo="-translate-x-full"
        >
          <div className="max-w-4xl mx-auto text-center ">
            <h2 className="text-base font-semibold leading-7 text-primary-600 ">
              HRFlex Packages
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Simple, Flexible Packages.
            </p>
          </div>
          <p className="max-w-2xl mx-auto mt-6 text-lg leading-8 text-center text-gray-600">
            HRFlex offers various packages that give you flexibility to scale
            for teams of all sizes
          </p>
        </Transition.Child>

        <Transition.Child
          enter="transition ease-linear duration-700 transform"
          enterFrom="translate-y-full"
          enterTo="-translate-y-0"
        >
          <div className="grid items-center max-w-lg grid-cols-1 mx-auto mt-16 gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
            {tiers
              .filter((item) => item.mainCard === true)
              .map((tier, tierIdx) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.featured
                      ? "relative bg-gradient-to-tl from-gray-800  to-black shadow-2xl sm:-ml-4"
                      : "bg-white/60 sm:mx-8 lg:mx-0",
                    tier.featured
                      ? ""
                      : tierIdx === 0
                      ? "rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl"
                      : "sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none",
                    "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
                  )}
                >
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.featured ? "text-primary-400" : "text-primary-600",
                      "text-base font-semibold leading-7"
                    )}
                  >
                    {tier.name}
                  </h3>

                  <p
                    className={classNames(
                      tier.featured ? "text-gray-300" : "text-slate-900",
                      "text-base leading-7"
                    )}
                  >
                    {tier.description}
                  </p>
                  <ul
                    className={classNames(
                      tier.featured ? "text-gray-300" : "text-slate-800",
                      "mt-6 space-y-3 text-sm leading-6"
                    )}
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className={classNames(
                            tier.featured
                              ? "text-primary-400"
                              : "text-primary-600",
                            "h-6 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.featured
                        ? "bg-primary-500 text-white shadow-sm hover:bg-primary-400 focus-visible:outline-primary-500"
                        : "text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300 focus-visible:outline-primary-600",
                      "mt-8 transition duration-200 ease-linear hover:shadow-regular hover:-translate-y-1 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
                    )}
                  >
                    Contact Sales
                  </a>
                </div>
              ))}
          </div>
        </Transition.Child>

        <Transition.Child
          enter="transition ease-linear duration-700 transform"
          enterFrom="translate-y-full"
          enterTo="-translate-y-0"
        >
          <AddOns />
        </Transition.Child>
      </div>
    </Transition>
  );
};

const AddOns = () => {
  const tier = tiers[1];
  const features = [
    {
      name: "Secure. Compliant",
      description:
        "Securely store and manage your employee data within HRFlex. Authenticate users with SSO/SAML or FlexAuth, keep comprehensive audit records and assign user-access based on defined roles.",
      href: "#",
      icon: CloudArrowUpIcon,
    },
    {
      name: "Data Integration",
      description:
        "Seamlessly integrate HRFlex with your existing systems and applications. Our integration manager guarantees seamless data flow, eradicating manual entry and reducing errors.",
      href: "#",
      icon: LockClosedIcon,
    },
    {
      name: "Customize, Scale",
      description:
        "Being agile is important, we know. That is why HRFlex is designed with adaptability and configurability in mind to streamline the workflows to meet your business needs.",
      href: "#",
      icon: ArrowPathIcon,
    },
    {
      name: "Analytics & Reporting",
      description:
        "Gain valuable insights into your HR data with advanced analytics and reporting tools, enabling data-driven decision-making and strategic planning.",
      href: "#",
      icon: CloudArrowUpIcon,
    },
    {
      name: "Employee Self Service",
      description:
        "Empower employees to access and update their personal information, manage benefits, and submit requests through a user-friendly self-service portal.",
      href: "#",
      icon: LockClosedIcon,
    },
    {
      name: "Compliance & Policy Management",
      description:
        "Ensure compliance with labor laws and regulations, manage policy updates, and track employee acknowledgments for a legally sound workplace.",
      href: "#",
      icon: ArrowPathIcon,
    },
  ];
  return (
    <div className="py-24 sm:py-16">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="lg:text-left">
          <h2 className="mt-16 text-3xl font-bold tracking-tight text-slate-700 sm:text-4xl">
            HRFlex Add-On Modules
          </h2>
          <p className="mt-2 leading-8 text-slate-600">
            Expand your HR Capabilities by complimenting the packages with our
            optional Add-Ons.
          </p>
        </div>
        <div className="max-w-2xl mx-auto mt-16 sm:mt-20 lg:mt-8 lg:max-w-none">
          <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-4">
            {tiers
              .filter((item) => item.mainCard === false)
              .map((tier, index) => (
                <div
                  key={index}
                  className={classNames(
                    tier.featured
                      ? "relative bg-gradient-to-tl from-gray-800  to-black shadow-2xl"
                      : " bg-gray-25 sm:mx-8 lg:mx-0",
                    "rounded-md p-8 ring-1 ring-gray-200 shadow-2xl shadow-gray-200"
                  )}
                >
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.featured ? "text-primary-400" : "text-primary-600",
                      "text-base font-semibold leading-7"
                    )}
                  >
                    {tier.name}
                  </h3>

                  {/* <p
                    className={classNames(
                      tier.featured ? "text-gray-300" : "text-gray-600",
                      "text-base leading-7"
                    )}
                  >
                    {tier.description}
                  </p> */}
                  <ul
                    className={classNames(
                      tier.featured ? "text-gray-300" : "text-slate-700",
                      "mt-6 space-y-3 text-sm leading-6"
                    )}
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className={classNames(
                            tier.featured
                              ? "text-primary-400"
                              : "text-primary-600",
                            "h-6 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  {/* <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.featured
                      ? "bg-primary-500 text-white shadow-sm hover:bg-primary-400 focus-visible:outline-primary-500"
                      : "text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300 focus-visible:outline-primary-600",
                    "mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
                  )}
                >
                  Contact Sales
                </a> */}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
