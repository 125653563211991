import { Feature } from "../components";
import { ContentModel } from "./content";
import {
  PresentationChartLineIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
export const heroContent: ContentModel = {
  header: "Recruitment",
  subtitle: "Talent Sourcing and Interview Management",
  body: "Efficiently manage the entire recruitment lifecycle with HRFlex™. From creating job postings and receiving applications to conducting interviews and making offers, our advanced recruitment module simplifies the hiring process, ensuring you attract top talent and build a high-performing team.",
  imageUrl: "/recruitment-main.png",
};

export const featureToast: ContentModel = {
  header: "Streamline Your Hiring Process",
  body: "HRFlex™ enables you to streamline your hiring process and make it more efficient. Create and manage job requisitions effortlessly, define job requirements, and collaborate with hiring managers in real-time. Our intuitive dashboard provides a centralized view of all ongoing job openings, making it easy to track progress and ensure timely hiring.",
  imageUrl: "/hiring-process.png",
};

export const attractTopTalent: ContentModel = {
  header: "Attract Top Talent",
  body: "Attracting the right talent is crucial to the success of your organization. HRFlex™ Recruitment Module helps you create compelling job openings and postings. With easy to use templates and dedicated branded career pages, you can showcase your company culture and values, making a strong impression on potential candidates.",
  imageUrl: "/attract-talent.svg",
};
export const seamlessOnboarding: ContentModel = {
  header: "Seamless Onboarding Experience",
  body: "Unlock a Seamless Onboarding Experience with our Recruitment Module. Your new hires will embark on their journey with confidence, knowing they’re part of an organization committed to providing a seamless and welcoming onboarding experience. Elevate your recruitment process with HRFlex™ and make every new chapter a success story.",
  imageUrl: "/seamless-onboarding.svg",
};

export const features: Feature[] = [
  {
    name: "Analytics & Reporting",
    description:
      "Managing a large pool of candidates can be overwhelming without the right tools. HRFlex™’s Recruitment Module simplifies candidate management with an intuitive interface and powerful search capabilities. Easily track and evaluate candidate profiles, schedule interviews, and collaborate with hiring teams. Our software also includes automated email notifications and reminders, ensuring that no candidate falls through the cracks.",
    href: "#",
    icon: UserGroupIcon,
  },
  {
    name: "Secure & Stable",
    description:
      "We understand the importance of data security. HRFlex™ employs robust security measures to protect your sensitive HR and candidate data. With our cloud-based solution, you can scale your recruitment processes effortlessly as your organization grows, without worrying about infrastructure constraints.",
    href: "#",
    icon: PresentationChartLineIcon,
  },
];
