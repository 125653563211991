import {
  ModuleHero,
  LeftRightContentImageCard,
  Button,
  SectionWithGrid,
} from "../components";
import { BasePageLayout } from "../components/BasePageLayout";
import {
  heroContent,
  featureToast,
  goalSetting,
  features,
} from "../content/Performance";

export default function PerformanceHome() {
  return (
    <BasePageLayout title="Performance">
      <div className="mt-16">
        <ModuleHero content={heroContent} />
        <LeftRightContentImageCard
          content={featureToast}
          skin={"dark"}
          contentDirection="left"
        />
        <LeftRightContentImageCard
          content={goalSetting}
          skin={"default"}
          contentDirection="right"
        />
        <SectionWithGrid grid="2" gridContent={features} />
      </div>
    </BasePageLayout>
  );
}
