import {
  ArrowPathIcon,
  BanknotesIcon,
  Bars3Icon,
  ChartBarIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export const products = [
  {
    header: "01",
    name: "Core HR",
    description:
      "HRFlex™ Core is the cornerstone of our HRIS offering, comprising of a suite of essential features tailored to meet the needs of modern businesses and streamline your HR operations.",
    href: "/core",
    icon: ArrowPathIcon,
    caption:
      "HR Essentials. Manage and automate Core Employee lifecycle processes",
  },
  {
    header: "02",
    name: "Compensation & Benefits",
    description:
      "Scalable for all kinds of payroll and payroll inputs. Save time and improve efficiency with HRFlex™ Compensation & Benefits Management",
    href: "/compensation",
    icon: BanknotesIcon,
    caption: "Scalable for all kinds of Payroll and Compensation inputs.",
  },
  {
    header: "03",
    name: "Performance Management",
    description:
      "HRFlex™’s Performance Management module streamlines evaluations, goal setting, and development planning.",
    href: "/performance",
    icon: ChartBarIcon,
    caption:
      "Streamline Performance evaluations, goal setting, and development planning.",
  },
  {
    header: "04",
    name: "Recruitment",
    description:
      "Efficiently manage the candidate selection process by tracking applications, reviewing resumes, and collaborating with hiring teams.",
    href: "/recruitment",
    icon: ChartPieIcon,
    caption: "Attract the right talent. Seamless Onboarding",
  },
  {
    header: "05",
    name: "Expense Management",
    description:
      "Simplify and automate your travel and expense management workflow and empower your organization to efficiently manage travel requests, track expenses, and streamline reimbursements.",
    href: "/expense",
    icon: SquaresPlusIcon,
    caption: "Capture and Manage your Employee Expenses",
  },
];
