import { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../lib/helperFunctions";
import { tiers, sections, Tier } from "../../content/Packages";
import { Link } from "react-router-dom";
function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export const ComparePlans = () => {
  return (
    <div className="relative pb-16 isolate sm:block sm:h-full sm:w-full bg-gradient-to-b from-gray-25 to-gray-100">
      {/* <div
        className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-sm sm:-mr-80 lg:-mr-96"
        aria-hidden="true"
      /> */}
      <div className="relative h-full mx-auto max-w-7xl">
        <svg
          className="absolute transform right-full translate-x-1/4 translate-y-1/4 lg:translate-x-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={2}
                height={2}
                className="text-primary-300"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa)"
          />
        </svg>
        {/* <svg
          className="absolute transform left-full -translate-x-1/4 -translate-y-3/4 md:-translate-y-1/3 lg:-translate-x-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={2}
                height={2}
                className="text-primary-300"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
          />
        </svg> */}
      </div>

      <h3 className="hidden px-4 mx-auto text-3xl font-bold tracking-tight text-center text-gray-600 lg:block">
        Compare HRFlex Packages and Features
      </h3>
      <div className="hidden px-6 mx-auto mt-16 isolate lg:px-8 max-w-7xl lg:block">
        <div className="relative -mx-8">
          {tiers.some((tier) => tier.mostPopular) ? (
            <div className="absolute inset-y-0 flex inset-x-4 -z-10">
              <div
                className="flex w-1/3 px-4"
                aria-hidden="true"
                style={{
                  marginLeft: `${
                    (tiers.findIndex((tier) => tier.mostPopular) + 1) * 33.3
                  }%`,
                }}
              >
                <div className="w-full border-t rounded-t-xl border-x border-primary-600/50 bg-primary-200/5" />
              </div>
            </div>
          ) : null}
          <table className="w-full text-left border-separate table-fixed border-spacing-x-8">
            <caption className="sr-only">Package comparison</caption>
            <colgroup>
              <col className="w-1/3" />
              <col className="w-1/3" />
              <col className="w-1/3" />
            </colgroup>
            <thead>
              <tr>
                <td />
                {tiers
                  .filter((item) => item.mainCard === true)
                  .map((tier) => (
                    <th
                      key={tier.id}
                      scope="col"
                      className="px-6 pt-6 xl:px-8 xl:pt-8"
                    >
                      <div className="text-lg font-semibold leading-7 text-gray-900">
                        {tier.name}
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <span className="sr-only">Package</span>
                </th>
                {tiers
                  .filter((item) => item.mainCard === true)
                  .map((tier) => (
                    <td key={tier.id} className="px-6 pt-2 xl:px-8">
                      <div className="flex items-baseline text-gray-700 gap-x-1">
                        <span className="text-sm font-light">
                          {tier.description}
                        </span>
                      </div>
                      <Link
                        to={tier.href}
                        className={classNames(
                          tier.mostPopular
                            ? "bg-primary-600 text-white hover:bg-primary-500"
                            : "text-primary-600 ring-1 ring-inset ring-primary-500 hover:ring-primary-400",
                          "mt-8 block transition duration-200 ease-linear hover:shadow-regular hover:-translate-y-1 rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                        )}
                      >
                        Contact Sales
                      </Link>
                    </td>
                  ))}
              </tr>
              {sections.map((section, sectionIdx) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      scope="colgroup"
                      colSpan={3}
                      className={classNames(
                        sectionIdx === 0 ? "pt-8" : "pt-16",
                        "pb-4 text-sm gap-4 font-semibold leading-6 text-gray-900"
                      )}
                    >
                      {section.name}{" "}
                      {section.isAddOn && (
                        <span className="px-2 py-1 text-xs font-medium rounded-md text-primary-600 bg-primary-50">
                          Add On
                        </span>
                      )}
                      <div className="absolute h-px mt-4 inset-x-8 bg-gray-900/10" />
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th
                        scope="row"
                        className="py-4 text-sm font-normal leading-6 text-gray-900"
                      >
                        {feature.name}
                        <div className="absolute h-px mt-4 inset-x-8 bg-gray-900/5" />
                      </th>
                      {tiers
                        .filter((item) => item.mainCard === true)
                        .map((tier) => (
                          <td key={tier.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.name as keyof Tier] ===
                            "string" ? (
                              <div className="text-sm leading-6 text-center text-gray-500">
                                {getProperty(
                                  feature.tiers,
                                  tier.name as keyof Tier
                                )}
                              </div>
                            ) : (
                              <>
                                {getProperty(
                                  feature.tiers,
                                  tier.name as keyof Tier
                                ) === true ? (
                                  <CheckIcon
                                    className="w-5 h-5 mx-auto text-primary-600"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="w-5 h-5 mx-auto text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.name as keyof Tier] ===
                                  true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
