import {
  HomePageHero,
  ClientsTicker,
  AccordionHolder,
  ComboCard,
  FlexAdvantage,
  Testimonials,
  FeaturesList,
} from "../components";
import { BasePageLayout } from "../components/BasePageLayout";

import { products } from "../content/SharedContents";

export default function Home() {
  return (
    <BasePageLayout title="Home">
      <main className="flex flex-col">
        <HomePageHero />

        <div className="">
          <ClientsTicker />
          <FlexAdvantage />
          <FeaturesList />
          <ComboCard id="services" dark={true}>
            <div className="px-6 pb-24 mx-auto md:px-8 max-w-7xl">
              <h2 className="max-w-md mt-2 text-3xl font-bold leading-normal tracking-tight text-gray-200 sm:text-5xl ">
                Simplify your HR processes
              </h2>

              <div className="pt-8">
                {products.map((competency, index) => (
                  <AccordionHolder
                    key={index}
                    dark={true}
                    defaultOpen={competency.header === "01"}
                    header={competency.header}
                    title={competency.name}
                    copy={competency.description}
                    link={competency.href}
                  />
                ))}
              </div>
            </div>
          </ComboCard>

          <Testimonials />
        </div>
        {/* <div className="flex">
          <p>a</p>
          <Image className="" src="/hero1.svg" fill alt="" />
        </div> */}
      </main>
    </BasePageLayout>
  );
}
