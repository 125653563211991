import { FC, useEffect } from "react";

import { Link } from "react-router-dom";
export interface PageError {
  code: string;
  codeDescription: string;
  copy: string;
  cta?: {
    label: string;
    link: string;
  };
}

export const ErrorPage: FC<{ error?: PageError }> = ({ error }) => {
  const pageError: PageError = {
    code: "404",
    copy: "This page does not exist or is no longer available",
    codeDescription: "Page not found",
    cta: {
      label: "Go Home",
      link: "/core",
    },
  };

  useEffect(() => {
    document.title = `HRFlex™ | 404 Page`;
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <ErrorElement error={error !== undefined ? error : pageError} />
    </div>
  );
};

//grid h-screen max-w-sm mx-auto -mt-10 text-left place-content-center

export const ErrorElement: FC<{ error: PageError }> = ({ error }) => {
  return (
    <>
      <div className="grid gap-5 mx-auto md:grid-cols-2 max-w-7xl">
        <div className="grid items-center justify-center place-content-center">
          <h1 className={`text-success-600 text-base font-medium`}>
            {error.code} Error
          </h1>

          <p className="my-3 text-5xl font-semibold text-gray-700">
            {error.codeDescription}
          </p>
          <p className="leading-6 text-gray-900">{error.copy}</p>
          {error?.cta && (
            <div className="flex gap-2 mt-8">
              <Link
                to={error.cta.link}
                type="submit"
                className="inline-flex items-center px-4 py-3 text-sm font-semibold text-white rounded-md shadow-sm bg-success-600 hover:bg-success-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                {error.cta.label}
              </Link>
            </div>
          )}
        </div>
        <div className="hidden md:grid">
          <img src={"/errorImage.svg"} alt="404 Error" />
        </div>
      </div>
    </>
  );
};
