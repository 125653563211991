import { FC } from "react";

import { ClientsTickerContent, clients } from "../../content/HomePage";

export const ClientsTicker = () => {
  return (
    <section className="pt-16 bg-white sm:pt-24">
      <div className="px-6 mx-auto space-y-6 max-w-7xl lg:px-8">
        <h2 className="max-w-3xl mx-auto text-3xl font-medium leading-relaxed text-center text-gray-600">
          {ClientsTickerContent.header}{" "}
          <span className="text-success-600">their people.</span>
        </h2>
        <p className="max-w-5xl pb-16 mx-auto leading-8 text-center text-gray-700">
          {ClientsTickerContent.body}
        </p>
        <div className="grid items-center justify-center max-w-2xl grid-cols-4 pb-8 mx-auto mt-10 place-items-center gap-x-8 gap-y-16 sm:max-w-xl sm:grid-cols-6 lg:grid-cols-4 sm:gap-x-10 lg:max-w-full lg:mx-0">
          {clients.map((item, index) => (
            <ClientIcon key={index} icon={item.icon} alt={item.alt} />
          ))}
        </div>
      </div>

      {/* <div className="max-w-5xl pl-16 mx-auto mt-24 bg-gray-200 rounded-2xl">
        <div className="flex items-center justify-between pt-16">
          <img className=" h-96 object-fit" src="/girl-only 1.png" alt="" />
          <div>
            <div className="max-w-sm p-8 mx-16 mb-16 text-2xl font-medium leading-relaxed text-gray-600 bg-gray-25 rounded-2xl">
              <span className="text-success-600">All-in-one solution</span> for
              comprehensive{" "}
              <span className="text-success-600">HR management</span>,
              eliminating the need for multiple disjointed systems.
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

const ClientIcon: FC<{ icon: string; alt: string }> = ({ icon, alt }) => {
  return (
    <img
      className="object-contain w-full col-span-2 max-h-12 lg:col-span-1"
      src={icon}
      alt={alt}
      width={158}
      height={48}
    />
  );
};
