import { Feature } from "../components";
import { ContentModel } from "./content";
import {
  CakeIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
export const heroContent: ContentModel = {
  header: "HRFlex™ Core",
  subtitle: "Your gateway to simplifying essential HR functions",
  body: "HRFlex™ Core is the cornerstone of our HRMS offering, comprising of a suite of essential features tailored to meet the needs of modern businesses and streamline your HR operations.",
  imageUrl: "/core-hero.svg",
};

export const featureToast: ContentModel = {
  header: "Streamline Travel Request Process",
  body: "HRFlex™ enables employees to submit travel requests with ease. Our intuitive interface allows users to enter essential travel details, including destination, dates, purpose, and estimated expenses. Approvers can review and approve requests online, facilitating quick and efficient decision-making. With automated, customizable notifications and prompts, everyone stays informed throughout the approval process.",
  imageUrl: "/travel-form.svg",
};

export const cardOne: ContentModel = {
  header: "Employee Records Management",
  body: "Centralize all your employee information in one secure repository, including personal details, contact information, employment history and other relevant documentation Streamline employee records management, including documentation, contracts, and certifications, ensuring compliance and accessibility.",
  imageUrl: "/automate-expense.svg",
};
export const cardTwo: ContentModel = {
  header: "Time Off & Leave Management",
  body: "Say goodbye to manual paperwork and complex approval processes. HRFlex™ allows employees to submit time off requests with just a few clicks. Our intuitive interface makes it easy to select the desired dates, specify the type of leave, and add additional notes for managers’ consideration. With automated notifications, managers are promptly informed of new requests, streamlining the approval process.",
  imageUrl: "/capture-expense.svg",
};
export const cardThree: ContentModel = {
  header: "Employee Self Service",
  body: "Empower your workforce with HRFlex™ Employee Self Service, designed to put control directly into the hands of your employees. With our intuitive features, your employees can access and manage their own information effortlessly, reducing administrative overhead and fostering a culture of autonomy and engagement",
  imageUrl: "/expense-analytics.png",
};
export const cardFour: ContentModel = {
  header: "Celebrations & Reminders",
  body: "We understand the importance of recognizing and celebrating the achievements and milestones of your team. With our Celebrations & Reminders module, you can ensure that no significant event goes unnoticed, plus you get to promote a positive and supportive work environment!",
  imageUrl: "efficient-reimbursements.png",
};

export const features: Feature[] = [
  {
    name: "Employee Self Service",
    description:
      "Empower your workforce with HRFlex™ Employee Self Service, designed to put control directly into the hands of your employees. With our intuitive features, your employees can access and manage their own information effortlessly, reducing administrative overhead and fostering a culture of autonomy and engagement",
    href: "#",
    icon: UserGroupIcon,
  },
  {
    name: "Celebrations & Reminders",
    description:
      "We understand the importance of recognizing and celebrating the achievements and milestones of your team. With our Celebrations & Reminders module, you can ensure that no significant event goes unnoticed, plus you get to promote a positive and supportive work environment!",
    href: "#",
    icon: CakeIcon,
  },
];
