import { testimonials } from "../../content/HomePage";

export const Testimonials = () => {
  return (
    <div className="py-24 bg-white sm:py-32">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-primary-600">
            What our Clients say
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            We have worked with amazing people, delivering amazing results
          </p>
        </div>
        <div className="flow-root mx-auto mt-16 sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:text-[0] grid sm:grid-cols-3">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="pt-8 sm:inline-block sm:w-full sm:px-4"
              >
                <figure className="p-8 text-sm leading-6 rounded-2xl bg-gray-50">
                  <blockquote className="pb-4 text-base leading-relaxed text-gray-600 border-b">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="flex items-center mt-6 gap-x-4">
                    <img
                      className="w-10 h-10 rounded-full bg-gray-50"
                      src={testimonial.author.imageUrl}
                      alt=""
                      width={40}
                      height={40}
                    />
                    <div>
                      <div className="font-semibold text-gray-900">
                        {testimonial.author.name}
                      </div>
                      <div className="text-gray-600">{`${testimonial.author.handle}`}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
