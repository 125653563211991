import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Secure. Compliant",
    description:
      "Securely store and manage your employee data within HRFlex. Authenticate users with SSO/SAML or FlexAuth, keep comprehensive audit records and assign user-access based on defined roles.",
    href: "#",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Data Integration",
    description:
      "Seamlessly integrate HRFlex with your existing systems and applications. Our integration manager guarantees seamless data flow, eradicating manual entry and reducing errors.",
    href: "#",
    icon: LockClosedIcon,
  },
  {
    name: "Customize, Scale",
    description:
      "Being agile is important, we know. That is why HRFlex is designed with adaptability and configurability in mind to streamline the workflows to meet your business needs.",
    href: "#",
    icon: ArrowPathIcon,
  },
  {
    name: "Analytics & Reporting",
    description:
      "Gain valuable insights into your HR data with advanced analytics and reporting tools, enabling data-driven decision-making and strategic planning.",
    href: "#",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Employee Self Service",
    description:
      "Empower employees to access and update their personal information, manage benefits, and submit requests through a user-friendly self-service portal.",
    href: "#",
    icon: LockClosedIcon,
  },
  {
    name: "Compliance & Policy Management",
    description:
      "Ensure compliance with labor laws and regulations, manage policy updates, and track employee acknowledgments for a legally sound workplace.",
    href: "#",
    icon: ArrowPathIcon,
  },
];

export const FlexAdvantage = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-success-600">
            All In One HCM system
          </h2>
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Experience the HRFlex Advantage
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Scalable solution that can cater to the needs of small and large
            businesses alike.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon
                    className="h-5 w-5 flex-none text-primary-600"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  {/* <p className="mt-6">
                    <a
                      href={feature.href}
                      className="text-sm font-semibold leading-6 text-primary-600"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
