import { Fragment, useState, useEffect, FC } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { products } from "../content/SharedContents";
import { FlexLogoFull, Footer, ScrollToTop } from ".";
import { Button, ICON_POSITION } from ".";
import { Link } from "react-router-dom";
import { Providers } from "../app/providers";

const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const BasePageLayout: FC<{
  children: React.ReactNode;
  title: string;
}> = ({ children, title }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [noBg, setNoBg] = useState(false);
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setNoBg(true) : setNoBg(false);
  };

  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    document.title = `HRFlex™ | ${title}`;
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <html lang="en" className="bg-gray-25 font-Inter">
      <body className={`bg-gray-25 tracking-tight`}>
        <div
          className={classNames(
            " w-full fixed top-0 z-50",
            noBg
              ? "bg-gray-25 shadow-sm bg-opacity-70 backdrop-blur backdrop-filter "
              : "bg-transparent"
          )}
        >
          <nav
            className="z-50 flex items-center justify-between p-3 mx-auto max-w-7xl lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">HRFlex</span>
                <FlexLogoFull />
              </a>
            </div>

            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 lg:hidden"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="w-6 h-6" aria-hidden="true" />
            </button>

            <div className="hidden px-12 lg:flex lg:gap-x-12">
              <div className="group">
                <div className="">
                  <button
                    onClick={() => setOpenDropdown(!openDropdown)}
                    className="inline-flex items-center text-sm font-semibold leading-6 text-gray-900 gap-x-1"
                  >
                    <span>Solutions</span>
                    <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
                  </button>

                  <Transition
                    as={Fragment}
                    show={true}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div className="absolute z-10 hidden w-screen px-4 -mt-2 -translate-x-1/2 left-1/2 max-w-max group-hover:block ">
                      <div className="flex flex-auto w-screen max-w-lg mt-5 overflow-hidden text-sm leading-6 bg-white rounded-md shadow-2xl ring-1 ring-gray-900/5 lg:max-w-lg">
                        <div className="flex flex-col flex-1">
                          <div className="flex-1">
                            <div className="grid grid-cols-1 p-4 gap-x-6">
                              {products.map((item) => (
                                <div
                                  key={item.name}
                                  className="relative flex items-center p-2 my-1 rounded-lg group gap-x-4 hover:bg-success-25"
                                >
                                  {/* <div className="flex items-center justify-center flex-none w-8 h-8 mt-1 rounded-lg bg-gray-50 group-hover:bg-white">
                                    <item.icon
                                      className="w-5 h-5 text-gray-600 group-hover:text-indigo-600"
                                      aria-hidden="true"
                                    />
                                  </div> */}
                                  <div>
                                    <Link
                                      to={item.href}
                                      className="text-sm font-semibold text-gray-800 hover:text-success-600"
                                    >
                                      {item.name}
                                      <span className="absolute inset-0" />
                                    </Link>
                                    <p className="text-xs text-gray-600">
                                      {item.caption}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>

              <Link
                to="/packages"
                className="text-sm font-medium leading-6 text-gray-900"
              >
                Packages
              </Link>
            </div>

            <div className="hidden lg:flex ">
              <Button
                link="mailto:info@hr-flex.com"
                componentType="link"
                label="Contact Us"
                icon={{
                  position: ICON_POSITION.trailing,
                  asset: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                      />
                    </svg>
                  ),
                }}
              />
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            {/* <div className="fixed inset-x-0 z-10 top-16"></div> */}
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full px-6 py-6 overflow-y-auto bg-white top-12 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flow-root mt-6">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="py-6 space-y-2">
                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                            Solutions
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {products.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                className="block py-2 pl-6 pr-3 text-sm leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                              >
                                {item.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    <Link
                      to="/packages"
                      className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                    >
                      Packages
                    </Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        <ScrollToTop />
        <Providers>{children}</Providers>

        <Footer />
      </body>
    </html>
  );
};

// bg-opacity-10 backdrop-blur backdrop-filter
