import { Feature } from "../components";
import { ContentModel } from "./content";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import {
  BanknotesIcon,
  ChartPieIcon,
  CubeTransparentIcon,
} from "@heroicons/react/24/outline";

export const heroContent: ContentModel = {
  header: "Compensation & Benefits",
  subtitle: "Simplified Payroll Administration, Automation and Compliance",
  body: "We understand that a well-structured and competitive compensation and benefits program is essential for attracting, retaining, and motivating top talent. HRFlex™ empowers your HR and finance teams to manage compensation accurately and effortlessly; reducing the risk of errors and ensuring compliance with tax laws and other regulatory requirements.",
  imageUrl: "/payroll-main.svg",
};

export const featureToast: ContentModel = {
  header: "Flexible Compensation Planning",
  body: "HRFlex™ allows you to design flexible compensation structures tailored to your organization’s needs. Create salary bands, set pay scales, and define various compensation components such as base salary, bonuses, commissions, and incentives. With HRFlex™’s compensation planning tools, you can align compensation with individual and company-wide performance goals.",
  imageUrl: "/hiring-process.png",
};

export const worryFree: ContentModel = {
  header: "Worry-free Payroll Processing",
  body: "HRFlex™ simplifies the entire compensation process, from compensation-related data entry to payslip generation. Our intuitive interface allows you to incorporate custom payments, employee voluntary contributions, recurrent payments, loans, exclusions, time sheets, and other relevant compensation information quickly and accurately. With automated calculations, tax deductions, and benefit adjustments, you can be confident in the accuracy and reliability of your compensation calculations.",
  imageUrl: "/payroll-processing.svg",
};

export const taxManagement: ContentModel = {
  header: "Comprehensive Tax Management",
  body: "Tax compliance is critical in compensation processing. HRFlex™’s Compensation Module handles complex tax calculations and ensures that you stay compliant with ever-changing tax regulations. Our system supports various tax setups, including federal, state, and local taxes, as well as deductions for benefits and retirement plans.",
  imageUrl: "/tax-1.svg",
};

export const features: Feature[] = [
  {
    name: "Automated Benefits Administration",
    description:
      "Simplify employee benefits administration with HRFlex™. Our module enables seamless enrollment, tracking, and management of employee benefits, including health insurance, retirement plans, leave policies, and more. Employees can access their payslips, view compensation history, download their pension/tax-related documents and make benefits selections through the secure self-service portal, reducing HR’s administrative burden and ensuring efficiency.",
    href: "#",
    icon: CubeTransparentIcon,
  },
  {
    name: "Reports & Analytics",
    description:
      "HRFlex™’s Compensation Module provides a comprehensive set of compensation reports and analytics. Generate compensation summaries, tax reports, and benefit summaries to gain valuable insights into your organization’s compensation data. Use analytics to identify trends, monitor labor costs, and make informed decisions to improve your compensation processes.",
    href: "#",
    icon: ChartPieIcon,
  },
  {
    name: "Data Security & Compliance",
    description:
      "We prioritize the security and confidentiality of your compensation data. HRFlex™ implements robust data security measures, including encryption and access controls, to protect sensitive compensation information. Our software also ensures compliance with industry standards and data privacy regulations.",
    href: "#",
    icon: LockClosedIcon,
  },
  {
    name: "HRFlex™ Loans",
    description:
      "Our loan management feature simplifies and automates the loan process, making it easy for employees to apply for loans and for HR teams to manage loan requests efficiently. Employees can submit loan applications online, specify loan amounts, and comply with pre-configured repayment terms. With flexibility in setting up repayment types and loan eligibility, HRFlex™ takes your loan administration to a new level.",
    href: "#",
    icon: BanknotesIcon,
  },
];
