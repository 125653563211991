import { Button, BUTTON_SIZES, ICON_POSITION } from "..";
import { HeroContent } from "../../content/HomePage";
import { ParallaxBanner } from "react-scroll-parallax";
export const HomePageHero = () => {
  return (
    <>
      <div className=" bg-gradient-to-r from-rose-100 to-teal-100 h-screen  inset-0 [clip-path:polygon(0%_0%,100%_0%,100%_calc(100%-14.5rem),0%_100%)] before:absolute before:z-1 before:inset-0 before:bg-dark/40 xl:[clip-path:polygon(0%_0%,100%_0%,100%_calc(100%-9rem),0%_100%)] lg:[clip-path:polygon(0%_0%,100%_0%,100%_calc(100%-5rem),0%_100%)]">
        <div className="flex items-center justify-between h-full px-16 mx-auto mt-8 max-w-7xl">
          <div className="z-10 max-w-md -mt-24 space-y-5">
            <div className="text-5xl font-semibold tracking-wide text-gray-600">
              {HeroContent.header}
            </div>
            <div className="relative text-4xl font-semibold text-left text-success-600">
              {HeroContent.subtitle}
              <svg
                className="absolute top-0 left-0"
                height="80"
                width="300"
                viewBox="0 40 100 20"
                fill="#f79009"
              >
                <path d="M 0 60 Q 50 40 200 60 Q 50 45 0 60 Z" />
              </svg>
            </div>

            <p className="py-4 font-medium text-gray-800">{HeroContent.body}</p>

            <Button
              label="Book a Demo"
              link="mailto:info@hr-flex.com"
              componentType="link"
              size={BUTTON_SIZES.lg}
              icon={{
                position: ICON_POSITION.trailing,
                asset: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                    />
                  </svg>
                ),
              }}
            />
          </div>

          <ParallaxBanner
            className="hidden object-cover lg:block lg:mx-3 h-3/5 lg:h-4/5"
            layers={[
              {
                children: (
                  <img
                    className="object-cover h-full mx-auto"
                    src="/hero1.svg"
                    alt=""
                  />
                ),
                expanded: false,
                speed: -8,
              },
            ]}
          />
          {/* <img className="object-cover h-5/6" src="/hero1.svg" alt="" /> */}
        </div>
      </div>
    </>
  );
};

// h-[calc(80%_-_10rem)]
