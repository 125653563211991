import { FC } from "react";

const features: string[] = [
  "Recruitment & Interview Management",
  "Outsourced Staff Management",
  "Manpower Planning",
  "Payroll Automation",
  "Online Candidate Testing",
  "HMO & Benefits Management",
  "Loan Management",
  "Exit Management & Settlements",
  "Celebrations & Reminders",
  "Appraisals, Checklists, Tasks",
  "Salary Review Simulation",
  "Conduct Management",
  "Time Off & Leave Management",
  "Data Integration Hub",
  "Workflow Automation",
  "Learning & Development",
  "Time-sheets & Attendance Management",
  "Employee Self Service",
  "Surveys & Feedback Mechanisms",
  "360 Balanced Scorecard",
  "Multi-Organization support",
  "Travel & Expense Management",
  "...and more",
];

export const FeaturesList = () => {
  return (
    <div className="w-full py-24 bg-gradient-to-b from-gray-900 to-gray-600 ">
      <div className="grid grid-cols-1 px-6 mx-auto max-w-7xl lg:px-8 gap-x-8 gap-y-10 md:grid-cols-3">
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
          A truly <span className="text-primary-600">consolidated</span> HR
          Solution
        </h2>
        <div className="hidden col-span-2 gap-3 space-y-3 sm:flow-root columns-2">
          {features.map((feature, index) => (
            <FeatureTab feature={feature} key={index} />
          ))}
        </div>
      </div>
      <div className="max-w-5xl mx-4 mt-12 bg-gray-200 lg:mx-auto lg:pl-16 rounded-2xl">
        <div className="items-center justify-between grid-cols-2 sm:grid">
          <img className="pt-8 h-96 object-fit" src="/girl-only 1.png" alt="" />
          <div className="hidden h-full py-16 bg-gray-600 rounded-tr-lg rounded-br-lg sm:block">
            <div className="p-8 my-auto text-2xl font-medium leading-relaxed text-gray-200 rounded-2xl">
              <span className="text-success-600">All-in-one solution</span> for
              comprehensive{" "}
              <span className="text-success-600">HR management</span>,
              eliminating the need for multiple disjointed systems.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureTab: FC<{ feature: string }> = ({ feature }) => {
  return (
    <p className="py-2.5 px-3 text-sm text-gray-25 rounded-md text-center bg-gray-25 shadow-sm bg-opacity-10 backdrop-blur backdrop-filter">
      {feature}
    </p>
  );
};

// border-b border-gray-900/10 pb-12
