import { Button } from ".";
import { classNames } from "../lib/helperFunctions";

export const Footer = () => {
  return (
    <footer className="bg-white">
      <CTASection />
      <div className="py-8 md:py-10 bg-alto">
        <div className="items-center justify-between px-8 mx-auto space-y-6 lg:flex max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-tight text-gray-600 uppercase">
              Contact Us
            </h2>
            <div
              className={classNames("bg-gray-900", "w-12 h-0.5 my-2 ")}
            ></div>
            <a
              href="mailto:info@hr-flex.com"
              className="py-8 text-xl font-medium tracking-tight text-gray-500 md:text-4xl"
            >
              info
              <span className="font-bold text-gray-900">@hr-flex.com</span>
            </a>
          </div>
          <div className="text-gray-600 text-md">
            <p className="font-bold text-gray-900">24B, Bashorun Okusanya,</p>
            <p className="text-sm font-light">Off Admiralty Way, Lekki</p>
            <p className="text-sm font-light">Lagos. Nigeria</p>
          </div>
        </div>
        <p className="mt-10 text-xs leading-5 text-center text-gray-500">
          &copy; 2024{" "}
          <span className="font-medium underline hover:text-primary-600">
            <a
              href="https://www.quetzalconsults.com"
              rel="noreferrer"
              target="_blank"
            >
              Quetzal Consults
            </a>
          </span>
          . All rights reserved.
        </p>
      </div>
    </footer>
  );
};

const CTASection = () => {
  return (
    <div className="relative overflow-hidden isolate bg-gradient-to-r from-gray-800 via-gray-900 to-black">
      <div className="px-6 py-12 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Like to boost your organization&apos;s productivity?
            {/* <br />
            Contact Sales with HRFlex today. */}
          </h2>
          <p className="max-w-xl mx-auto mt-6 text-lg leading-8 text-gray-300">
            HRFlex™ is a state-of-the-art HR Cloud software designed to
            revolutionize the way organizations manage their HR processes.
          </p>
          <div className="flex items-center justify-center max-w-xs mx-auto mt-10 gap-x-6">
            <Button
              componentType="link"
              label="Contact Sales"
              fillWidth={true}
              link="mailto:info@hr-flex.com?subject=Contact Sales"
            />
          </div>
        </div>
      </div>
      {/* <svg
        viewBox="0 0 1024 1024"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        aria-hidden="true"
      >
        <circle
          cx={512}
          cy={512}
          r={512}
          fill="url(#8d958450-c69f-4251-94bc-4e091a323369)"
          fillOpacity="0.7"
        />
        <defs>
          <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#E935C1" />
          </radialGradient>
        </defs>
      </svg> */}
    </div>
  );
};
