import { Feature } from "../components";
import { ContentModel } from "./content";
import {
  PresentationChartLineIcon,
  UserGroupIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
export const heroContent: ContentModel = {
  header: "Expense Management",
  subtitle: "Simplify and automate your travel and expense management workflow",
  body: "Efficient management of travel and expense processes is crucial for organizations to control costs, ensure compliance, and streamline reimbursements. HRFlex™’s Travel and Expense Module is a comprehensive cloud-based solution designed to simplify and automate your travel and expense management workflow. With powerful features and a user-friendly interface, HRFlex™ empowers your organization to efficiently manage travel requests, track expenses, and streamline reimbursements.",
  imageUrl: "travel-main.svg",
};

export const featureToast: ContentModel = {
  header: "Streamline Travel Request Process",
  body: "HRFlex™ enables employees to submit travel requests with ease. Our intuitive interface allows users to enter essential travel details, including destination, dates, purpose, and estimated expenses. Approvers can review and approve requests online, facilitating quick and efficient decision-making. With automated, customizable notifications and prompts, everyone stays informed throughout the approval process.",
  imageUrl: "/travel-form.svg",
};

export const cardOne: ContentModel = {
  header: "Automated Expense Policy Compliance",
  body: "Our Travel and Expense Module simplifies expense policy compliance. You can define expense policies within the system, including spending limits, permissible expense categories, and approval workflows. The software automatically validates expense claims against policy rules, ensuring adherence and preventing overspending. Requests can be uniquely routed or escalated for review automatically (based on predefined policies and guidelines), ensuring compliance before reimbursements are processed.",
  imageUrl: "/automate-expense.svg",
};
export const cardTwo: ContentModel = {
  header: "Capture and Track Expenses",
  body: "Effortlessly track expenses with HRFlex™’s expense management tools. Users can enter expense details, attach receipts, and allocate expenses to relevant projects or vendors. Our system supports various expense types, including travel-related expenses, meals, accommodation, transportation, and more. By capturing expenses in real-time, you can maintain accurate records and ensure compliance with company policies.",
  imageUrl: "/capture-expense.svg",
};
export const cardThree: ContentModel = {
  header: "Real-time Analytics and Reporting",
  body: "HRFlex™’s Travel and Expense Module provides powerful monitoring and reporting capabilities. Generate real-time reports to gain insights into travel expenses, spending patterns, and reimbursement trends. Identify cost-saving opportunities, track policy compliance, and make data-driven decisions to optimize your travel and expense management strategies.",
  imageUrl: "/expense-analytics.png",
};
export const cardFour: ContentModel = {
  header: "Efficient Reimbursements",
  body: "Say goodbye to manual paperwork and delays with HRFlex™’s streamlined reimbursement process. Our software enables HR teams to review and process expense reimbursements quickly and accurately. With integration options, reimbursements can be seamlessly integrated with financial and disbursement systems, ensuring timely and accurate payments to employees.",
  imageUrl: "efficient-reimbursements.png",
};

export const features: Feature[] = [
  {
    name: "Efficient Reimbursements",
    description:
      "Say goodbye to manual paperwork and delays with HRFlex™’s streamlined reimbursement process. Our software enables HR teams to review and process expense reimbursements quickly and accurately. With integration options, reimbursements can be seamlessly integrated with financial and disbursement systems, ensuring timely and accurate payments to employees.",
    href: "#",
    icon: HandThumbUpIcon,
  },
  {
    name: "Real-time Analytics and Reporting",
    description:
      "HRFlex™’s Travel and Expense Module provides powerful monitoring and reporting capabilities. Generate real-time reports to gain insights into travel expenses, spending patterns, and reimbursement trends. Identify cost-saving opportunities, track policy compliance, and make data-driven decisions to optimize your travel and expense management strategies.",

    href: "#",
    icon: PresentationChartLineIcon,
  },
];
