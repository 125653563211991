import { FC } from "react";
import {
  ButtonProps,
  BUTTON_SIZES,
  BUTTON_SKIN,
  ICON_POSITION,
} from "./Buttons.types";
import { classNames } from "../../lib/helperFunctions";
import { Link } from "react-router-dom";
/** Only use this button if you require an action performed on a page, for navigation, use Link instead */
export const Button: FC<ButtonProps> = ({
  size,
  skin,
  label,
  onClick,
  type,
  fillWidth,
  componentType,
  link,
  destructive,
  icon,
  disabled,
  isLoading,
}) => {
  let sizeClass: string;
  let skinStyleClass: string;

  switch (size) {
    case BUTTON_SIZES.sm:
      sizeClass = `${
        skin === BUTTON_SKIN.link || BUTTON_SKIN.linkColor ? "py-0" : "py-1"
      } ${fillWidth ? "w-full mx-auto" : "px-4 inline-flex"}`;
      break;
    case BUTTON_SIZES.md:
      sizeClass = `py-2.5
       ${fillWidth ? "w-full mx-auto" : "px-4 inline-flex"}`;

      break;
    case BUTTON_SIZES.lg:
      sizeClass = `py-3
      ${fillWidth ? "w-full mx-auto" : "px-4 inline-flex"}`;
      break;
    default:
      sizeClass =
        (skin === BUTTON_SKIN.link || BUTTON_SKIN.linkColor) &&
        `py-1 ${fillWidth ? "w-full mx-auto" : "px-4 inline-flex"}`;
      break;
  }

  switch (skin) {
    case BUTTON_SKIN.primary:
      skinStyleClass = `${
        destructive
          ? "bg-error-600 border-error-600"
          : "bg-success-600 border-primary-600"
      } border hover:bg-${
        destructive ? "error" : "primary"
      }-800 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 ${
        destructive
          ? "focus-visible:outline-error-200"
          : "focus-visible:outline-primary-200"
      }`;
      break;
    case BUTTON_SKIN.secondary:
      skinStyleClass = `bg-white ring-1 ${
        destructive ? "ring-error-300" : "ring-gray-300"
      } hover:bg-${destructive ? "error" : "gray"}-50 ${
        destructive ? "text-error-700" : "text-gray-700"
      } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 ${
        destructive
          ? "focus-visible:outline-error-100"
          : "focus-visible:outline-gray-100"
      } `;
      break;
    case BUTTON_SKIN.secondaryColor:
      skinStyleClass = `${
        destructive ? "bg-error-50" : "bg-primary-50"
      } ring-1 ring-${destructive ? "error" : "primary"}-300 ${
        destructive ? "hover:bg-error-200" : "hover:bg-primary-200"
      }  ${
        destructive ? "text-error-900" : "text-primary-900"
      } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 ${
        destructive
          ? "focus-visible:outline-error-100"
          : "focus-visible:outline-primary-100"
      }`;
      break;
    case BUTTON_SKIN.link:
      skinStyleClass = `hover:bg-${destructive ? "error" : "gray"}-50 text-${
        destructive ? "error" : "gray"
      }-700 hover:text-${
        destructive ? "error" : "gray"
      }-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 ${
        destructive
          ? "focus-visible:outline-error-100"
          : "focus-visible:outline-gray-100"
      }`;
      break;
    case BUTTON_SKIN.linkColor:
      skinStyleClass = ` text-${
        destructive ? "error" : "primary"
      }-700 hover:text-${
        destructive ? "error" : "primary"
      }-900 focus:outline-none`;
      break;

    default:
      skinStyleClass = `bg-${
        destructive ? "error" : "primary"
      }-600 ring-1 ring-${destructive ? "error" : "primary"}-600 hover:bg-${
        destructive ? "error" : "primary"
      }-800 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-${
        destructive ? "error" : "primary"
      }-200`;
      break;
  }

  return (
    <>
      {componentType === "link" ? (
        <Link
          to={link!}
          className={classNames(
            sizeClass,
            skinStyleClass,
            `items-center text-sm font-medium text-center rounded-md gap-x-2 sm:text-sm transition duration-200 ease-linear hover:shadow-regular hover:-translate-y-1 ${
              (disabled || isLoading) && "cursor-not-allowed"
            } `
          )}
        >
          <ButtonLabel label={label} isLoading={isLoading} icon={icon} />
        </Link>
      ) : (
        <button
          disabled={disabled || isLoading}
          type={type}
          onClick={onClick}
          className={classNames(
            sizeClass,
            skinStyleClass,
            `items-center text-sm font-medium text-center rounded-md gap-x-2 sm:text-sm ${
              (disabled || isLoading) && "cursor-not-allowed"
            } `
          )}
        >
          <ButtonLabel label={label} isLoading={isLoading} icon={icon} />
        </button>
      )}
    </>
  );
};

const ButtonLabel: FC<{
  label: string;
  icon?: { position: ICON_POSITION; asset: JSX.Element };
  isLoading?: boolean;
}> = ({ label, isLoading, icon }) => {
  return (
    <>
      {/* {label} */}
      {icon ? (
        icon.position === ICON_POSITION.leading ? (
          <div className="flex items-center justify-center gap-x-2">
            <span className={`${isLoading && "animate-spin"}`}>
              {icon.asset}
            </span>
            {label}
          </div>
        ) : (
          <div className="flex items-center justify-between px-4 gap-x-2">
            {label}
            <span className={`${isLoading && "animate-spin"}`}>
              {icon.asset}
            </span>
          </div>
        )
      ) : (
        label
      )}
    </>
  );
};

Button.defaultProps = {
  size: BUTTON_SIZES.md,
  skin: BUTTON_SKIN.primary,
  type: "button",
  componentType: "button",
  destructive: false,
};

export const IconButton: FC<{ onClick: () => void; skin: string }> = (
  props
) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className={`${props.skin} p-2 rounded-full shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600`}
    >
      {/* <Icon size={ICON_SIZES.md} icon={IconList.xMark} /> */}
    </button>
  );
};
