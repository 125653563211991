export const FlexLogoFull = () => {
  return (
    <svg
      width="107"
      height="32"
      viewBox="0 0 107 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2973 5.6817C18.3556 4.82979 22.7681 5.9139 25.9241 8.93441C30.8474 13.6464 30.8474 21.2858 25.9241 25.9974C24.7071 27.1621 23.3031 28.0384 21.8047 28.6274C24.0772 28.1502 26.2388 27.0661 28.0061 25.3746C32.9294 20.6627 32.9294 13.0233 28.0061 8.3117C24.3003 4.76452 18.8608 3.88822 14.2973 5.6817Z"
        fill="#EE8322"
      />
      <path
        d="M4.32885 20.9998C3.30155 16.5171 4.60909 11.6427 8.25147 8.15668C13.9335 2.71849 23.1463 2.71849 28.8284 8.15668C30.2331 9.50113 31.2898 11.0519 32 12.7069C31.4246 10.1966 30.1175 7.80894 28.0774 5.85641C22.3953 0.418223 13.183 0.418223 7.50049 5.85641C3.22277 9.95053 2.16608 15.9593 4.32885 20.9998Z"
        fill="#009444"
      />
      <path
        d="M20.0841 29.0367C15.4004 30.0199 10.3074 28.7685 6.66499 25.2825C0.98291 19.8443 0.98291 11.0273 6.66499 5.58874C8.06974 4.24429 9.69005 3.23295 11.4193 2.55322C8.79641 3.10391 6.30166 4.35495 4.26156 6.30748C-1.42052 11.7457 -1.42052 20.563 4.26156 26.0012C8.53929 30.0953 14.8175 31.1067 20.0841 29.0367Z"
        fill="#ED1C24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.433 11.1327C102.433 10.1535 103.249 9.36804 104.273 9.36804C105.308 9.36804 106.102 10.1535 106.102 11.1327C106.102 12.1334 105.308 12.9188 104.273 12.9188C103.249 12.9188 102.433 12.1334 102.433 11.1327ZM102.89 11.1345C102.89 11.9199 103.478 12.5437 104.284 12.5437C105.068 12.5437 105.645 11.9194 105.645 11.1452C105.645 10.3602 105.068 9.72524 104.273 9.72524C103.478 9.72524 102.89 10.3597 102.89 11.1345Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.687 11.1649V11.1863C104.862 11.251 104.959 11.3801 105.013 11.6172C105.068 11.886 105.101 11.9937 105.144 12.0584H104.697C104.653 12.0053 104.623 11.8874 104.584 11.734C104.575 11.7003 104.566 11.6648 104.556 11.628C104.524 11.4346 104.415 11.3484 104.186 11.3484H103.99V12.0584H103.576V10.2937C103.739 10.2615 103.969 10.2401 104.262 10.2401C104.6 10.2401 104.752 10.2937 104.883 10.3692C104.981 10.4441 105.057 10.5839 105.057 10.7563C105.057 10.9496 104.905 11.1006 104.687 11.1649ZM104.001 11.0545H104.197C104.426 11.0545 104.611 10.9791 104.611 10.7963C104.611 10.6353 104.491 10.5279 104.23 10.5279C104.121 10.5279 104.045 10.5386 104.001 10.5493V11.0545Z"
        fill="#344054"
      />
      <path
        d="M65.9722 22.7999V10.4559C65.9722 10.0551 66.1142 9.71426 66.398 9.43378C66.6819 9.1533 67.0269 9.01306 67.4325 9.01306H76.1125V11.3777H68.8114V14.3432H74.2467V15.2649C74.2467 15.6657 74.1047 16.0066 73.8209 16.287C73.537 16.5675 73.192 16.7078 72.7864 16.7078H68.8114V22.7994H65.9722V22.7999Z"
        fill="#344054"
      />
      <path
        d="M77.2314 9.01306H78.6916C79.0701 9.01306 79.3943 9.14015 79.6649 9.39385C79.9349 9.64804 80.0705 9.96212 80.0705 10.3356V22.7994H77.2314V9.01306V9.01306Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.7813 19.5136H88.7534C88.4286 19.5136 88.1787 19.5808 88.0038 19.7137C87.8278 19.8477 87.7263 19.9538 87.6997 20.0342L87.4765 20.2547C87.4085 20.3219 87.3345 20.3687 87.2532 20.395C86.9284 20.5825 86.5367 20.6755 86.0769 20.6755C85.3736 20.6755 84.8463 20.4622 84.4949 20.0342C84.1159 19.6334 83.9129 19.0194 83.8863 18.1906H90.9025C90.9292 18.1375 90.943 18.044 90.943 17.9101V17.7499V17.4694C90.943 15.7062 90.5103 14.3306 89.6449 13.3416C88.7524 12.3799 87.4952 11.8988 85.8728 11.8988C84.3313 11.8988 83.1278 12.3935 82.2629 13.3815C81.4246 14.3437 81.0057 15.6799 81.0057 17.3896C81.0057 19.1801 81.4384 20.5557 82.3038 21.5174C83.1692 22.5064 84.3727 23.0001 85.9137 23.0001C87.1847 23.0001 88.2393 22.6933 89.0776 22.0783C89.9159 21.4911 90.4836 20.6355 90.7813 19.5136ZM88.0234 16.3874H83.8862C83.9128 15.6935 84.1154 15.1589 84.4948 14.7844C84.8733 14.4104 85.3602 14.223 85.9551 14.223C86.6041 14.223 87.1043 14.4104 87.4557 14.7844C87.78 15.1321 87.9692 15.6663 88.0234 16.3874Z"
        fill="#344054"
      />
      <path
        d="M101.122 22.8H97.8771L95.9709 19.4337L93.9833 22.8H90.6982L94.2268 17.3097L90.9007 12.1398H94.0237L95.8895 15.3463L97.9579 11.8998C98.2014 11.4187 98.6069 11.1786 99.1747 11.1786H101.608L97.5523 17.3506L101.122 22.8Z"
        fill="#344054"
      />
      <path
        d="M63.2857 19.911C62.5475 18.7601 61.9607 17.9595 61.5256 17.5087C61.0905 17.0579 60.5387 16.6438 59.8701 16.2671C61.2139 16.0787 62.2236 15.623 62.8993 14.8994C63.5746 14.1758 63.9127 13.2589 63.9127 12.1487C63.9127 11.2741 63.6898 10.497 63.2441 9.81707C62.7983 9.13763 62.2049 8.66472 61.4631 8.39883C60.7214 8.13294 59.5325 8 57.8957 8H54.6248V10.5034H56.8931C58.1465 10.5034 58.8989 10.5201 59.1492 10.5539C59.6508 10.6348 60.0386 10.8281 60.3138 11.1342C60.5889 11.4404 60.7265 11.8426 60.7265 12.3406C60.7265 12.7845 60.6219 13.1549 60.4133 13.4507C60.2046 13.747 59.9152 13.9555 59.5462 14.0767C59.1771 14.1979 58.2511 14.2582 56.7677 14.2582H56.7235H54.6253H45.0425V8.00049H42V22.798H45.0425V16.6296H55.529C56.0239 16.6482 56.4052 16.6998 56.6626 16.7871C56.99 16.8979 57.2997 17.1035 57.5921 17.4027C57.8846 17.702 58.4278 18.427 59.2218 19.5779L61.4571 22.798H65.1549L63.2857 19.911Z"
        fill="#009444"
      />
      <rect
        x="51.1932"
        y="16.974"
        width="3.04957"
        height="5.82401"
        fill="#009444"
      />
      <rect
        x="51.1932"
        y="8.00055"
        width="3.04957"
        height="5.91387"
        fill="#009444"
      />
    </svg>
  );
};

export const FlexLogoFullLight = () => {
  return (
    <svg
      width="107"
      height="32"
      viewBox="0 0 107 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2973 5.6817C18.3556 4.82979 22.7681 5.9139 25.9241 8.93441C30.8474 13.6464 30.8474 21.2858 25.9241 25.9974C24.7071 27.1621 23.3031 28.0384 21.8047 28.6274C24.0772 28.1502 26.2388 27.0661 28.0061 25.3746C32.9294 20.6627 32.9294 13.0233 28.0061 8.3117C24.3003 4.76452 18.8608 3.88822 14.2973 5.6817Z"
        fill="#EE8322"
      />
      <path
        d="M4.32885 20.9998C3.30155 16.5171 4.60909 11.6427 8.25147 8.15668C13.9335 2.71849 23.1463 2.71849 28.8284 8.15668C30.2331 9.50113 31.2898 11.0519 32 12.7069C31.4246 10.1966 30.1175 7.80894 28.0774 5.85641C22.3953 0.418223 13.183 0.418223 7.50049 5.85641C3.22277 9.95053 2.16608 15.9593 4.32885 20.9998Z"
        fill="#009444"
      />
      <path
        d="M20.0841 29.0367C15.4004 30.0199 10.3074 28.7685 6.66499 25.2825C0.98291 19.8443 0.98291 11.0273 6.66499 5.58874C8.06974 4.24429 9.69005 3.23295 11.4193 2.55322C8.79641 3.10391 6.30166 4.35495 4.26156 6.30748C-1.42052 11.7457 -1.42052 20.563 4.26156 26.0012C8.53929 30.0953 14.8175 31.1067 20.0841 29.0367Z"
        fill="#ED1C24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.433 11.1327C102.433 10.1535 103.249 9.36804 104.273 9.36804C105.308 9.36804 106.102 10.1535 106.102 11.1327C106.102 12.1334 105.308 12.9188 104.273 12.9188C103.249 12.9188 102.433 12.1334 102.433 11.1327ZM102.89 11.1345C102.89 11.9199 103.478 12.5437 104.284 12.5437C105.068 12.5437 105.645 11.9194 105.645 11.1452C105.645 10.3602 105.068 9.72524 104.273 9.72524C103.478 9.72524 102.89 10.3597 102.89 11.1345Z"
        fill="#98A2B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.687 11.1649V11.1863C104.862 11.251 104.959 11.3801 105.013 11.6172C105.068 11.886 105.101 11.9937 105.144 12.0584H104.697C104.653 12.0053 104.623 11.8874 104.584 11.734C104.575 11.7003 104.566 11.6648 104.556 11.628C104.524 11.4346 104.415 11.3484 104.186 11.3484H103.99V12.0584H103.576V10.2937C103.739 10.2615 103.969 10.2401 104.262 10.2401C104.6 10.2401 104.752 10.2937 104.883 10.3692C104.981 10.4441 105.057 10.5839 105.057 10.7563C105.057 10.9496 104.905 11.1006 104.687 11.1649ZM104.001 11.0545H104.197C104.426 11.0545 104.611 10.9791 104.611 10.7963C104.611 10.6353 104.491 10.5279 104.23 10.5279C104.121 10.5279 104.045 10.5386 104.001 10.5493V11.0545Z"
        fill="#98A2B3"
      />
      <path
        d="M65.9722 22.7999V10.4559C65.9722 10.0551 66.1142 9.71426 66.398 9.43378C66.6819 9.1533 67.0269 9.01306 67.4325 9.01306H76.1125V11.3777H68.8114V14.3432H74.2467V15.2649C74.2467 15.6657 74.1047 16.0066 73.8209 16.287C73.537 16.5675 73.192 16.7078 72.7864 16.7078H68.8114V22.7994H65.9722V22.7999Z"
        fill="#98A2B3"
      />
      <path
        d="M77.2314 9.01306H78.6916C79.0701 9.01306 79.3943 9.14015 79.6649 9.39385C79.9349 9.64804 80.0705 9.96212 80.0705 10.3356V22.7994H77.2314V9.01306V9.01306Z"
        fill="#98A2B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.7813 19.5136H88.7534C88.4286 19.5136 88.1787 19.5808 88.0038 19.7137C87.8278 19.8477 87.7263 19.9538 87.6997 20.0342L87.4765 20.2547C87.4085 20.3219 87.3345 20.3687 87.2532 20.395C86.9284 20.5825 86.5367 20.6755 86.0769 20.6755C85.3736 20.6755 84.8463 20.4622 84.4949 20.0342C84.1159 19.6334 83.9129 19.0194 83.8863 18.1906H90.9025C90.9292 18.1375 90.943 18.044 90.943 17.9101V17.7499V17.4694C90.943 15.7062 90.5103 14.3306 89.6449 13.3416C88.7524 12.3799 87.4952 11.8988 85.8728 11.8988C84.3313 11.8988 83.1278 12.3935 82.2629 13.3815C81.4246 14.3437 81.0057 15.6799 81.0057 17.3896C81.0057 19.1801 81.4384 20.5557 82.3038 21.5174C83.1692 22.5064 84.3727 23.0001 85.9137 23.0001C87.1847 23.0001 88.2393 22.6933 89.0776 22.0783C89.9159 21.4911 90.4836 20.6355 90.7813 19.5136ZM88.0234 16.3874H83.8862C83.9128 15.6935 84.1154 15.1589 84.4948 14.7844C84.8733 14.4104 85.3602 14.223 85.9551 14.223C86.6041 14.223 87.1043 14.4104 87.4557 14.7844C87.78 15.1321 87.9692 15.6663 88.0234 16.3874Z"
        fill="#98a2b3"
      />
      <path
        d="M101.122 22.8H97.8771L95.9709 19.4337L93.9833 22.8H90.6982L94.2268 17.3097L90.9007 12.1398H94.0237L95.8895 15.3463L97.9579 11.8998C98.2014 11.4187 98.6069 11.1786 99.1747 11.1786H101.608L97.5523 17.3506L101.122 22.8Z"
        fill="#98A2B3"
      />
      <path
        d="M63.2857 19.911C62.5475 18.7601 61.9607 17.9595 61.5256 17.5087C61.0905 17.0579 60.5387 16.6438 59.8701 16.2671C61.2139 16.0787 62.2236 15.623 62.8993 14.8994C63.5746 14.1758 63.9127 13.2589 63.9127 12.1487C63.9127 11.2741 63.6898 10.497 63.2441 9.81707C62.7983 9.13763 62.2049 8.66472 61.4631 8.39883C60.7214 8.13294 59.5325 8 57.8957 8H54.6248V10.5034H56.8931C58.1465 10.5034 58.8989 10.5201 59.1492 10.5539C59.6508 10.6348 60.0386 10.8281 60.3138 11.1342C60.5889 11.4404 60.7265 11.8426 60.7265 12.3406C60.7265 12.7845 60.6219 13.1549 60.4133 13.4507C60.2046 13.747 59.9152 13.9555 59.5462 14.0767C59.1771 14.1979 58.2511 14.2582 56.7677 14.2582H56.7235H54.6253H45.0425V8.00049H42V22.798H45.0425V16.6296H55.529C56.0239 16.6482 56.4052 16.6998 56.6626 16.7871C56.99 16.8979 57.2997 17.1035 57.5921 17.4027C57.8846 17.702 58.4278 18.427 59.2218 19.5779L61.4571 22.798H65.1549L63.2857 19.911Z"
        fill="#009444"
      />
      <rect
        x="51.1932"
        y="16.974"
        width="3.04957"
        height="5.82401"
        fill="#009444"
      />
      <rect
        x="51.1932"
        y="8.00055"
        width="3.04957"
        height="5.91387"
        fill="#009444"
      />
    </svg>
  );
};
