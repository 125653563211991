import { FC } from "react";
import { classNames } from "../lib/helperFunctions";

export const ComboCard: FC<{
  dark: boolean;
  children: JSX.Element;
  id: string;
}> = ({ dark, children, id }) => {
  return (
    <div
      id={id}
      className={classNames(dark ? "bg-black" : "bg-primary-light", "pt-24")}
    >
      {children}
    </div>
  );
};
